import React from "react";

function Fretboard({ result, gameComponent, children }) {
    return (
        <svg width={1912} height={353} >
            {/* fretboard */}
            <path
                strokeWidth={10}
                stroke="#fff"
                d="M21 17h1870v318H21zM131 22v308M241 22v308M351 22v308M461 22v308M571 22v308M681 22v308M791 22v308M901 22v308m110-308v308m110-308v308m110-308v308m110-308v308m110-308v308m110-308v308m110-308v308m110-308v308m110 0"
            />
            {/*12 fret club*/}
            <path
                d="M1293.442 265.077c4.214-8.822-.813-21.799-12.152-18.729-11.05 2.992-8.625 15.914-1.899 20.382-12.753-1.864-14.739-5.138-17.508-7.1v20.833c2.961-2.111 4.755-5.944 17.683-8.192-6.715 4.503-9.043 18.038 1.848 20.987 10.713 2.901 16.814-10.175 11.964-18.87 5.599 8.338 23.987 11.332 23.987-4.46 0-15.947-18.748-13.286-23.923-4.85z"
                style={{
                    marker: "none",
                }}
                color="#000"
                fill="#fff"
                stroke="#000"
                strokeWidth={0.27165}
                display="block"
                overflow="visible"
            />
            {/*12 fret diamons*/}
            <path
                d="M1317.5 212.05c-9.66-5.805-18.952-12.34-27.877-18.978-8.924 6.951-18.216 13.59-27.875 18.978 9.843 5.596 19.227 11.714 27.875 18.978 8.925-7.056 18.4-13.486 27.876-18.978z"
                fill="red"
                fillRule="evenodd"
            />
            <path
                d="M1291.35 132.344c-18.708-18.321-32.278-.361-23.346 14.117-9.745-2.733-10.512-4.237-13.079-5.896v15.632c2.592-2.007 3.334-4.114 13.023-6.25-8.491 14.783 6.317 30.362 23.403 13.96 9.383-10.727 17.95-14.892 19.166-15.782-1.014-.853-9.957-5.88-19.166-15.78z"
                fill="#fff"
                fillRule="evenodd"
                stroke="#000"
                strokeWidth={0.17115}
            />
            <path
                d="M1255.008 85.475c.093-.051.763-.273 1.49-.492 4.606-1.388 9.27-3.965 14.987-8.28 2.148-1.621 4.05-3.147 9.066-7.273 5.807-4.778 7.668-6.146 10.234-7.528 1.515-.816 3.82-1.662 5.29-1.94 1.467-.279 3.864-.283 5.113-.01 5.078 1.11 8.909 5.621 9.355 11.015.51 6.179-2.61 11.435-8.431 14.196l-1.3.617 1.027.464c1.526.69 2.558 1.354 3.845 2.475 3.278 2.854 4.884 6.251 4.89 10.343.002 1.833-.15 2.883-.644 4.445-.74 2.337-1.891 4.095-3.707 5.661-4.733 4.082-11.181 3.598-19.079-1.43-1.842-1.173-4.567-3.289-7.984-6.2-3.935-3.35-5.935-4.988-8.14-6.665-5.286-4.018-10.089-6.721-14.587-8.208-.686-.227-1.325-.464-1.42-.528-.221-.148-.224-.54-.005-.662z"
                fill="red"
            />
            <path
                d="M962.442 174.077c4.214-8.822-.813-21.799-12.152-18.729-11.05 2.992-8.625 15.914-1.899 20.382-12.753-1.864-14.739-5.138-17.508-7.1v20.833c2.961-2.111 4.755-5.944 17.683-8.192-6.715 4.503-9.043 18.038 1.848 20.987 10.713 2.901 16.814-10.175 11.964-18.87 5.599 8.338 23.987 11.332 23.987-4.46 0-15.947-18.748-13.286-23.923-4.85z"
                style={{
                    marker: "none",
                }}
                color="#000"
                fill="#fff"
                stroke="#000"
                strokeWidth={0.27165}
                display="block"
                overflow="visible"
            />
            <path
                d="M763.5 179.05c-9.66-5.805-18.952-12.34-27.877-18.978-8.924 6.951-18.216 13.59-27.875 18.978 9.843 5.596 19.227 11.714 27.875 18.978 8.925-7.056 18.4-13.486 27.876-18.978z"
                fill="red"
                fillRule="evenodd"
            />
            <path
                d="M528.35 162.844c-18.708-18.321-32.278-.361-23.346 14.117-9.745-2.733-10.512-4.237-13.079-5.896v15.632c2.592-2.007 3.334-4.114 13.023-6.25-8.491 14.783 6.317 30.362 23.403 13.96 9.383-10.727 17.95-14.892 19.166-15.782-1.014-.853-9.957-5.88-19.166-15.78z"
                fill="#fff"
                fillRule="evenodd"
                stroke="#000"
                strokeWidth={0.17115}
            />
            <path
                d="M264.008 178.475c.093-.051.763-.273 1.49-.492 4.606-1.388 9.27-3.965 14.987-8.28 2.148-1.621 4.05-3.147 9.066-7.273 5.807-4.778 7.668-6.146 10.234-7.528 1.515-.816 3.82-1.662 5.29-1.94 1.467-.279 3.864-.283 5.113-.01 5.078 1.11 8.909 5.621 9.355 11.015.51 6.179-2.61 11.435-8.431 14.196l-1.3.617 1.027.464c1.526.69 2.558 1.354 3.845 2.475 3.278 2.854 4.884 6.251 4.89 10.343.002 1.833-.15 2.883-.644 4.445-.74 2.337-1.891 4.095-3.707 5.661-4.733 4.082-11.181 3.598-19.079-1.43-1.842-1.173-4.567-3.289-7.984-6.2-3.935-3.35-5.935-4.988-8.14-6.665-5.286-4.018-10.089-6.721-14.587-8.208-.686-.227-1.325-.464-1.42-.528-.221-.148-.224-.54-.005-.662z"
                fill="red"
            />
            <path
                d="M1840.35 162.844c-18.708-18.321-32.278-.361-23.346 14.117-9.745-2.733-10.512-4.237-13.079-5.896v15.632c2.592-2.007 3.334-4.114 13.023-6.25-8.491 14.783 6.317 30.362 23.403 13.96 9.383-10.727 17.95-14.892 19.166-15.782-1.014-.853-9.957-5.88-19.166-15.78z"
                fill="#fff"
                fillRule="evenodd"
                stroke="#000"
                strokeWidth={0.17115}
            />
            <path
                d="M1585.008 178.475c.093-.051.763-.273 1.49-.492 4.606-1.388 9.27-3.965 14.987-8.28 2.148-1.621 4.05-3.147 9.066-7.273 5.807-4.778 7.668-6.146 10.234-7.528 1.515-.816 3.82-1.662 5.29-1.94 1.467-.279 3.864-.283 5.113-.01 5.078 1.11 8.909 5.621 9.355 11.015.51 6.179-2.61 11.435-8.431 14.196l-1.3.617 1.027.464c1.526.69 2.558 1.354 3.845 2.475 3.278 2.854 4.884 6.251 4.89 10.343.002 1.833-.15 2.883-.644 4.445-.74 2.337-1.891 4.095-3.707 5.661-4.733 4.082-11.181 3.598-19.079-1.43-1.842-1.173-4.567-3.289-7.984-6.2-3.935-3.35-5.935-4.988-8.14-6.665-5.286-4.018-10.089-6.721-14.587-8.208-.686-.227-1.325-.464-1.42-.528-.221-.148-.224-.54-.005-.662z"
                fill="red"
            />
            {/* string */}
            <path
                className="prefix__string"
                fill="rgba(204,204,204,0.678)"
                d="M21 25h1864v5H21zM21 
                59h1864v5H21zM21 
                93h1864v5H21zM21 
                127h1864v5H21zM21 
                161h1864v5H21zM21 
                195h1864v5H21zM21 
                229h1864v5H21zM21 
                263h1864v5H21zM21 
                297h1864v5H21zM21 
                331h1864v5H21zM21z"
            />
            {children}
        </svg>
    );
}
export default Fretboard;
