import react from "react"
import { Card } from "reactstrap"

export const ProfileView = () => {

    return (
        <Card>
            Welcome user
        </Card>
    )
}